export class LoadPracticesPlans  {
    public static readonly type = '[Practices] LoadPracticesPlans';
    constructor() {}
}
export class ClearPracticesPlans  {
    public static readonly type = '[Practices] LoadPracticesPlans';
    constructor() {}
}
export class LoadPracticesCategory  {
    public static readonly type = '[Practices] LoadPracticesCategory';
    constructor() {}
}
export class LoadPracticesVideo  {
    public static readonly type = '[Practices] LoadPracticesVideo';
    constructor() {}
}
export class LoadPracticesQuiz  {
    public static readonly type = '[Practices] LoadPracticesQuiz';
    constructor() {}
}
export class LoadPracticesWorksheet  {
    public static readonly type = '[Practices] LoadPracticesWorksheet';
    constructor() {}
}


export class LoadQuizQuestions {
    static readonly type = '[Practice] LoadQuizQuestions';
    constructor(public category_id: string) {}
}
export class FillQuizQuestions {
    static readonly type = '[Practice] FillQuizQuestions';
    constructor(public questions: any) {}
}
export class ClearQuizQuestions {
    static readonly type = '[Practice] ClearQuizQuestions';
    constructor() {}
}

export class UpdateQuizQuestionsResult {
    static readonly type = '[Practice] UpdateQuizQuestionsResult';
    constructor(public result: any) {}
}
export class ClearQuizQuestionsResult {
    static readonly type = '[Practice] ClearQuizQuestionsResult';
    constructor() {}
}
