import {UserModel} from "../_models/user.model";

export class initUser  {
  public static readonly type = '[User] initUser';
  constructor(public user: any) {}
}
export class updateUser{
  public static readonly type = '[User] updateUser';
  constructor(public user) {}
}
export class SwitchUserToChild  {
  public static readonly type = '[User] SwitchUserToChild';
  constructor(public child: UserModel) {}
}
export class SwitchUserToDefault  {
  public static readonly type = '[User] SwitchUserToDefault';
  constructor() {}
}

export class ChangeUserGradeAction  {
  public static readonly type = '[User] ChangeUserGradeAction';
  constructor(public data: string) {}
}


export class CountDownEnergy  {
  public static readonly type = '[User] CountDownEnergy';
  constructor() {}
}
export class CountUpEnergy  {
  public static readonly type = '[User] CountUpEnergy';
  constructor() {}
}
export class UpdateUpEnergy  {
  public static readonly type = '[User] UpdateUpEnergy';
  constructor(public count: number, public from_timer?: boolean) {}
}


export class CountDownLife  {
  public static readonly type = '[User] CountDownLife';
  constructor() {}
}
export class CountUpLife  {
  public static readonly type = '[User] CountUpLife';
  constructor() {}
}
export class UpdateUpLife  {
  public static readonly type = '[User] UpdateUpLife';
  constructor(public count: number, public from_timer?: boolean) {}
}

export class CountDownSpendStar  {
  public static readonly type = '[User] CountDownSpendStar';
  constructor(public count: number, public reason: string) {}
}
export class CountUpSpendStar  {
  public static readonly type = '[User] CountUpSpendStar';
  constructor(public count: number, public reason: string) {}
}


export class CountUpPoints  {
  public static readonly type = '[User] CountUpPoints';
  constructor(public count: number, public reason?: string) {}
}
