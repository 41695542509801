<div class="bh-modal bh-modal-{{interface.type}}" *ngIf="interface.status" (click)="actionCloseModal($event)">
  <div class="bh-modal-container" [class.bg-confetti-animated]="interface.type == 'quiz-strike'">
    <div class="bh-modal-header">
      <div class="bh-modal-header-title" [ngSwitch]="interface.type">
       {{'modal.titles.'+interface.type | transloco}}
      </div>
      <div class="bh-modal-header-action">
        <button *ngIf="!interface.no_close" aria-label="x" (click)="actionCloseModal()">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
    <div class="bh-modal-body" [ngSwitch]="interface.type">
      <ng-container *ngSwitchCase="'invite_friends'" [ngTemplateOutlet]="BH_INVITE_FRIENDS"></ng-container>
      <ng-container *ngSwitchCase="'change_language'" [ngTemplateOutlet]="BH_CHANGE_LANGUAGE"></ng-container>
      <ng-container *ngSwitchCase="'change_multipasport_level'" [ngTemplateOutlet]="BH_CHANGE_MULTIPASPORT_LEVEL"></ng-container>
      <ng-container *ngSwitchCase="'get_premium'" [ngTemplateOutlet]="BH_GET_PREMIUM"></ng-container>
      <ng-container *ngSwitchCase="'only_for_premium'" [ngTemplateOutlet]="BH_ONLY_PREMIUM"></ng-container>
        <ng-container *ngSwitchCase="'parent_pin'" [ngTemplateOutlet]="BH_PARENT_PIN"></ng-container>
        <ng-container *ngSwitchCase="'isset_premium'" [ngTemplateOutlet]="BH_ISSET_PREMIUM"></ng-container>
      <ng-container *ngSwitchCase="'remind_pin'" [ngTemplateOutlet]="BH_REMIND_PIN"></ng-container>
      <ng-container *ngSwitchCase="'minicart'" [ngTemplateOutlet]="BH_MINICART"></ng-container>
        <ng-container *ngSwitchCase="'no_bve'" [ngTemplateOutlet]="BH_NO_BVE"></ng-container>
        <ng-container *ngSwitchCase="'login'" [ngTemplateOutlet]="BH_LOGIN"></ng-container>
        <ng-container *ngSwitchCase="'register'" [ngTemplateOutlet]="BH_REGISTER"></ng-container>
        <ng-container *ngSwitchCase="'practice_exit'" [ngTemplateOutlet]="BH_PRACTICE_EXIT"></ng-container>
        <ng-container *ngSwitchCase="'quiz-strike'" [ngTemplateOutlet]="BH_QUIZ_STRIKE"></ng-container>
    </div>
  </div>
</div>
<ng-template #BH_INVITE_FRIENDS>
  <bh-invite></bh-invite>
</ng-template>
<ng-template #BH_CHANGE_LANGUAGE>
  <bh-language-select></bh-language-select>
</ng-template>
<ng-template #BH_CHANGE_MULTIPASPORT_LEVEL>
  <bh-change-multipasport></bh-change-multipasport>
</ng-template>
<ng-template #BH_GET_PREMIUM>
 <bh-get-premium [plan]="interface.plan"></bh-get-premium>
</ng-template>
<ng-template #BH_PARENT_PIN>
  <div class="bh-modal-info">
      <p>Your child's account has been successfully added!</p>
      <h2>{{interface._id}}</h2>
      <span>We've set a PIN code for you to access the parental section of your account. Please make sure to save this PIN code in a secure place. </span>
      <small>If you ever forget it, don't worry—you can always use the PIN reminder feature, and we'll send a recovery email to your registered email address.</small>
      <small>You can also change the PIN code anytime in your account settings.</small>
      <button class="bh-btn bh-btn-sap" (click)="actionCloseModal()">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          <span>{{'buttons.understood' | transloco}}</span>
      </button>
  </div>
</ng-template>
<ng-template #BH_ONLY_PREMIUM>
  <div class="bh-modal-error-premium">
    <img ngSrc="./assets/images/backgrounds/bg_operation_only_premium.svg" alt="error" height="144" width="144"/>
    <p>{{'errors.only_premium' | transloco}}</p>
    <button class="bh-btn bh-btn-yellow" (click)="actionIssuePremium()">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
      </svg>
      <span>{{'buttons.premium_reminder' | transloco}}</span>
    </button>
  </div>
</ng-template>
<ng-template #BH_ISSET_PREMIUM>
    <div class="bh-modal-error-premium">
        <img ngSrc="./assets/images/icons/objects/dialog-ok.gif" alt="error" height="400" width="400"/>
        <p>You already have a premium subscription, unlocking all exclusive features and benefits. Enjoy unlimited access, priority support, and premium-only content.</p>
        <button class="bh-btn bh-btn-sap" routerLink="/account/subscriptions/">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
            </svg>
            <span>Visit Premium Features</span>
        </button>
    </div>
</ng-template>

<ng-template #BH_REMIND_PIN>
    <div class="bh-modal-error-bve">
        <img ngSrc="./assets/images/icons/objects/mailbox.gif" alt="error" height="400" width="400"/>
        <p>PIN code recovery instructions have been sent to your email address <strong>{{interface.user?.email}}</strong></p>
        <button class="bh-btn bh-btn-sap" (click)="actionCloseModal()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
            <span>{{'buttons.understood' | transloco}}</span>
        </button>
    </div>
</ng-template>
<ng-template #BH_MINICART>
  <bh-minicart></bh-minicart>
</ng-template>
<ng-template #BH_NO_BVE>
    <div class="bh-modal-error-bve">
        <img ngSrc="./assets/images/backgrounds/bg_dino_comp.svg" alt="error" height="144" width="144"/>
        <p>At this time, video explanations are not available for this book. However, all the information and activities provided are designed to be easy to follow and engaging for young learners. We are always working to enhance the experience, so video content may be added in the future. Thank you for your understanding!</p>
        <button class="bh-btn bh-btn-sap" (click)="actionCloseModal()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
            <span>{{'buttons.understood' | transloco}}</span>
        </button>
    </div>
</ng-template>
<ng-template #BH_REGISTER>
    <bh-create-multipasport></bh-create-multipasport>
</ng-template>
<ng-template #BH_LOGIN>
    <bh-login [_text]="'Log in to get access to learning materials.'"></bh-login>
</ng-template>
<ng-template #BH_PRACTICE_EXIT>
    <div class="bh-modal-error-bve">
        <img ngSrc="./assets/images/backgrounds/bg_dino_comp.svg" alt="error" height="144" width="144"/>
        <p>Are you sure want to leave? All your progress will be lost.</p>
        <div class="flex items-center gap-2 w-full">
            <button class="bh-btn bh-btn-link-rose w-full" (click)="actionExitQuiz()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
                </svg>
                <span>Go Out</span>
            </button>
            <button class="bh-btn bh-btn-sap w-full" (click)="actionContinueQuiz()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                <span>Continue</span>
            </button>
        </div>
    </div>
</ng-template>
<ng-template #BH_QUIZ_STRIKE>
    <div class="bh-modal-error-bve">
        <img ngSrc="./assets/images/icons/objects/cup.gif" alt="error" height="400" width="400"/>
        <p>You've just earned <b>10</b> extra points!</p>
        <span>Way to go! Keep up the great work, and who knows how many more points you could win!</span>
        <button class="bh-btn bh-btn-sap" (click)="actionCloseModal()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
            <span>{{'buttons.close' | transloco}}</span>
        </button>
    </div>
</ng-template>
