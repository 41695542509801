import {API} from "../../api";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {CategoryModel} from "../_models/practice.model";
import {QuestionModel} from "../_models/question.model";


@Injectable({
  providedIn: 'root',
})
export class PracticesService {

  constructor(private http: HttpClient) {
  }

  public getGradePractice(grade_id: string) {
    const _url = API.url('practices/tree/'+grade_id);
    return this.http.get<CategoryModel[]>(_url).toPromise();
  }
  public getPracticeVideos(lectures_id: string) {
    const _url = API.url('practices/videos/'+lectures_id);
    return this.http.get<any[]>(_url).toPromise();
  }
  public getPracticeQuiz(quiz_id: string) {
    const _url = API.url('practices/questions/parent/'+quiz_id);
    return this.http.get<QuestionModel[]>(_url).toPromise();
  }
  public getPracticeWorksheet(worksheet_id: string){
    return this.http.get<any>(API.url('practices/worksheets/' + worksheet_id)).toPromise();
  }

}
