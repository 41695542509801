import {
  Action,
  createSelector,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store
} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {CategoryModel, PracticesModel} from "../_models/practice.model";
import {AuthAllowAccess} from "../../auth/_actions/auth.actions";
import {PracticesService} from "../_services/practice.service";
import {
  ClearPracticesPlans,
  ClearQuizQuestions,
  ClearQuizQuestionsResult,
  FillQuizQuestions,
  LoadPracticesCategory,
  LoadPracticesPlans,
  LoadPracticesQuiz,
  LoadPracticesVideo,
  LoadPracticesWorksheet,
  LoadQuizQuestions,
  UpdateQuizQuestionsResult
} from "../_actions/practice.actions";
import {UserState} from "../../user/_state/user.state";
import {RouterNavigation, RouterState} from "@ngxs/router-plugin";
import {TranslocoService} from "@ngneat/transloco";
import {UserService} from "../../user/_services/user.service";


export const _PracticesDefault: PracticesModel = {
  worksheet: undefined,
  current: [],
  videos: [],
  quiz: [],
  quiz_result: [],
  plans: []
};

@State<PracticesModel>({
  name: 'V7_APO_PRACTICE',
  defaults: _PracticesDefault,
})
@Injectable()
export class PracticesState implements NgxsOnInit, NgxsOnChanges {

  constructor(private store: Store, private practicesService: PracticesService, private userService: UserService, private transloco: TranslocoService) {}

  ngxsOnInit(ctx?: StateContext<any>): any {}
  ngxsOnChanges(change: NgxsSimpleChange<PracticesModel>): void {}

  @Selector()
  static selectPractice( state: PracticesModel ) {
    return state;
  }
  @Selector()
  static selectCurrentPractice( state: PracticesModel ) {
    return state.current;
  }
  @Selector()
  static selectCurrentPlans( state: PracticesModel ) {
    return state.plans;
  }

  static selectCurrentPracticeBySlug( _slug: string ) {
    return createSelector([ PracticesState.selectCurrentPractice ], ( _practice: CategoryModel[]) => {
      return _practice.find(x => x.slug === _slug);
    });
  }
  static selectPracticeById(_id: string) {
    return createSelector(
        [PracticesState.selectCurrentPractice],
        (_practice: CategoryModel[]) => {
          function findCategoryById(
              categories: CategoryModel[],
              id: string
          ): CategoryModel | null {
            for (const category of categories) {
              if (category._id === id) {
                return category;
              }
              if (category.children && category.children.length > 0) {
                const found = findCategoryById(category.children as CategoryModel[], id);
                if (found) {
                  return found;
                }
              }
            }
            return null;
          }

          return findCategoryById(_practice, _id);
        }
    );
  }

  @Selector()
  static selectVideoLectures( state: PracticesModel ) {
    return state.videos;
  }
  @Selector()
  static selectQuiz( state: PracticesModel ) {
    return [...state.quiz].sort((a, b) => a.order - b.order);
  }
  @Selector()
  static selectQuizResult(state: PracticesModel) {
    return state.quiz_result;
  }

  @Selector()
  static selectWorksheet( state: PracticesModel ) {
    return state.worksheet;
  }

  @Action(AuthAllowAccess)
  authAllowAccess(ctx: StateContext<PracticesModel>) {
    ctx.dispatch(new LoadPracticesCategory());
  }

  @Action(RouterNavigation)
  async routerNavigation(ctx: StateContext<PracticesModel>, payload) {
    if(payload.routerState.params.worksheet_id) {
      this.store.dispatch(new LoadPracticesWorksheet());
    }
    if(payload.routerState.params.lectures_id) {
      this.store.dispatch(new LoadPracticesVideo());
    }
    if(payload.routerState.params.quiz_id) {
      //this.store.dispatch(new LoadQuizQuestions(payload.routerState.params.quiz_id));
    }
  }

  @Action(LoadPracticesPlans)
  async loadPracticesPlans(ctx: StateContext<PracticesModel>) {
    let _state_current = ctx.getState();
    if (_state_current.plans.length == 0) {
      const _plans = await this.userService.getEducationPlans().toPromise();
      if(_plans) {
        ctx.patchState({
          ..._state_current,
          plans: _plans
        });
      }

    }
  }

  @Action(ClearPracticesPlans)
  async clearPracticesPlans(ctx: StateContext<PracticesModel>) {
    let _state_current = ctx.getState();
    ctx.patchState({
      ..._state_current,
      plans: []
    });
  }

  @Action(LoadPracticesCategory)
  async loadPracticesCategory(ctx: StateContext<PracticesModel>) {
    const _state_user = this.store.selectSnapshot(UserState);
    let _practices_current_state = ctx.getState();
    if (_state_user.settings.grade !== null) {
      let _practices_by_grade = await this.practicesService.getGradePractice(_state_user.settings.grade._id);
      if(_practices_by_grade) {
        if(JSON.stringify(_practices_current_state.current) !== JSON.stringify(_practices_by_grade)) {
          ctx.patchState({
            ..._practices_current_state,
            current: _practices_by_grade,
          });
        }
        ctx.dispatch(new ClearPracticesPlans());
        ctx.dispatch(new LoadPracticesPlans());
      }
    }
  }

  @Action(LoadPracticesVideo)
  async loadPracticesVideo(ctx: StateContext<PracticesModel>) {
    let _state_current = ctx.getState();
    const _state_user = this.store.selectSnapshot(UserState);
    const _state_router = this.store.selectSnapshot(RouterState);
    let _videos = await this.practicesService.getPracticeVideos(_state_router.state.params.lectures_id);
    if(_videos.length > 0) {
      ctx.patchState({
        ..._state_current,
        videos: _videos
      });
    }
  }
  @Action(LoadPracticesWorksheet)
  async loadPracticesWorksheet(ctx: StateContext<PracticesModel>) {
    let _state_current = ctx.getState();
    const _state_user = this.store.selectSnapshot(UserState);
    const _state_router = this.store.selectSnapshot(RouterState);
/*    let _worksheet = await this.practicesService.getPracticeWorksheet(_state_router.state.params.worksheet_id);
    if(_worksheet) {
      ctx.patchState({
        ..._state_current,
        worksheet: _worksheet
      });
    }*/

    const _state_worksheets = _state_current.current.find(_cur => _cur.slug === 'worksheets');
    let _state_worksheet = this._findWorksheetById(_state_worksheets, _state_router.state.params.worksheet_id);
    if(_state_worksheet) {
      ctx.patchState({
        ..._state_current,
        worksheet: _state_worksheet
      });
    }
  }

  @Action(LoadPracticesQuiz)
  async loadPracticesQuiz(ctx: StateContext<PracticesModel>) {
    let _state_current = ctx.getState();
    const _state_user = this.store.selectSnapshot(UserState);
    const _state_router = this.store.selectSnapshot(RouterState);
    let _quiz = await this.practicesService.getPracticeQuiz(_state_router.state.params.quiz_id);
    if(_quiz.length > 0) {
      ctx.patchState({
        ..._state_current,
        quiz: _quiz
      });
    }
  }

  @Action(LoadQuizQuestions)
  async loadQuizQuestions(ctx: StateContext<PracticesModel>, payload) {
    let _quiz = await this.practicesService.getPracticeQuiz(payload.category_id);
    if(_quiz.length > 0) {
      this.store.dispatch(new FillQuizQuestions(_quiz));
    }
  }

  @Action(FillQuizQuestions)
  fillQuizQuestions(ctx: StateContext<PracticesModel>, payload) {
    console.log('FillQuizQuestions');
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      quiz: payload.questions,
    });
  }
  @Action(ClearQuizQuestions)
  clearQuizQuestions(ctx: StateContext<PracticesModel>) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      quiz: [],
    });
  }


  @Action(UpdateQuizQuestionsResult)
  updateQuizQuestionsResult(ctx: StateContext<PracticesModel>, payload) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      quiz_result: payload.result
    });
  }

  @Action(ClearQuizQuestionsResult)
  clearQuizQuestionsResult(ctx: StateContext<PracticesModel>) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      quiz_result: []
    });
  }


  _findWorksheetById(rootCategory: CategoryModel, targetId: string): CategoryModel | null {
    // Создаем стек для хранения категорий
    const stack: CategoryModel[] = [rootCategory];

    // Перебираем элементы стека
    while (stack.length > 0) {
      const currentCategory = stack.pop();

      if (!currentCategory) continue;

      // Проверяем, соответствует ли текущий объект целевому ID
      if (currentCategory._id === targetId) {
        return currentCategory;
      }

      // Если есть дети, добавляем их в стек для дальнейшего поиска
      if (currentCategory.children) {
        stack.push(...currentCategory.children as CategoryModel[]);
      }
    }

    // Если объект не найден, возвращаем null
    return null;
  }



}
