import {ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from "@ngxs/store";
import {TranslocoService} from "@ngneat/transloco";
import {DOCUMENT} from "@angular/common";
import {ModalState} from "../../../_stores/modal/_state/modal.state";
import {ModalCloseAction, ModalOpenAction} from "../../../_stores/modal/_actions/modal.actions";
import {UserState} from "../../../_stores/user/_state/user.state";
import {Router} from "@angular/router";
import {ConfirmNavigation} from "../../../_stores/crumbs/_actions/crumbs.actions";

@Component({
  selector: 'bh-modal',
  templateUrl: './bh-modal.component.html',
  styleUrls: ['./bh-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BhModalComponent implements OnInit {
  interface: {
    user: any,
    status: boolean,
    type: string,
    _id: string,
    no_close: boolean,
    plan: any,
    _cache: any
  } = {
    user: undefined,
    status: false,
    type: undefined,
    _id: undefined,
    no_close: false,
    plan: undefined,
    _cache: {
      subscribe: {
        modal: undefined,
        user: undefined
      }
    }
  };
  constructor(private store: Store, private cdr: ChangeDetectorRef, private transloco: TranslocoService, @Inject(DOCUMENT) private document: Document, private router: Router) {}

  ngOnInit(): void {
    this.initModalStatus();
    this.initUser();
  }
  ngOnDestroy(): void {
    if (!this.interface._cache) {return}
    Object.keys(this.interface._cache.subscribe).forEach((_subscribe) => {
      if (this.interface._cache.subscribe[_subscribe]) {
        this.interface._cache.subscribe[_subscribe].unsubscribe();
      }
    });
  }
  initUser(): void {
    this.interface._cache.subscribe.user = this.store.select(UserState.selectUser).subscribe((_user: any)=> {
      this.interface.user = {..._user};
      this.cdr.detectChanges();
    });
  }

  initModalStatus(): void {
    this.interface._cache.subscribe.modal = this.store.select(ModalState.selectStatus).subscribe((res: any) => {
      if(!res) {
        return;
      }


      this.interface.status = res.status;
      this.interface.type = res.type;
      this.interface._id = res._id;
      this.interface.no_close = !res.close;
      this.interface.plan = res.plan;
      if (this.interface.status) {
        this.document.body.classList.add('bh-modal-open');
      } else {
        this.document.body.classList.remove('bh-modal-open');
      }

      this.cdr.detectChanges();
    });
  }

  actionCloseModal(e?): void {
    if (this.interface.no_close) {
      return;
    }
    if (!e || (e && e.target.className === 'bh-modal')) {
      this.store.dispatch(new ModalCloseAction());
    }
  }

  actionIssuePremium(): void {
    this.store.dispatch(new ModalOpenAction('get_premium'));
  }

  actionContinueQuiz(e?): void {
    if (!e || (e && e.target.className === 'bh-modal')) {
      this.store.dispatch(new ModalCloseAction());
      if (window['modalCallback']) {
        window['modalCallback'](false); // Пользователь выбрал "Продолжить"
      }
    }
  }

  actionExitQuiz(e?): void {
    if (!e || (e && e.target.className === 'bh-modal')) {
      //this.store.dispatch(new ModalCloseAction());
      // Вызываем callback, чтобы разрешить навигацию
      let urlSegments = this.router.url.split('/');
      if (urlSegments[urlSegments.length - 1] === '') {
        urlSegments.pop();
      }
      urlSegments.pop();
      const newUrl = urlSegments.join('/');
      if (newUrl) {
        this.router.navigate([newUrl]);
      }

      if (window['modalCallback']) {
        window['modalCallback'](true); // Пользователь выбрал "Выйти"

      }
    }
  }
}
