import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserModel} from "../../../_stores/user/_models/user.model";
import {Store} from "@ngxs/store";
import {UserState} from "../../../_stores/user/_state/user.state";
import {StripeCardNumberComponent, StripeService} from "ngx-stripe";
import {StripeCardElementOptions, StripeElementsOptions} from "@stripe/stripe-js";
import {UserService} from "../../../_stores/user/_services/user.service";
import {StripeElementLocale} from "@stripe/stripe-js/types/stripe-js/elements-group";
import {TRANSLOCO_SCOPE} from "@ngneat/transloco";
import {ModalCloseAction} from "../../../_stores/modal/_actions/modal.actions";
import {AuthStatus} from "../../../_stores/auth/_actions/auth.actions";
import {Router} from "@angular/router";

@Component({
  selector: 'bh-get-premium',
  templateUrl: './bh-get-premium.component.html',
  styleUrls: ['./bh-get-premium.component.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'components/get-premium', alias: 'components.get-premium'  } }],
})
export class BhGetPremiumComponent  implements OnInit, OnDestroy {
  @Input('plan') set _plan(_plan) {
    if (_plan) {
      this.interface.plan = _plan;
      console.log(_plan);
    }
  }


  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;
  interface: {
    submit: boolean,
    subscribes: boolean,
    user: UserModel,
    plan: any,
    payment: {
      stripe: {
        customer_id: string,
        elementsOptions: StripeElementsOptions,
        cardOptions: StripeCardElementOptions
      }
    },
    subscription: {
      price_id?: string,
      amount?: number
    },
    _cache: any
  } = {
    submit: false,
    subscribes: true,
    user: undefined,
    plan: undefined,
    payment: {
      stripe: {
        customer_id: undefined,
        elementsOptions: {
          locale: 'en'
        },
        cardOptions: {
          style: {
            base: {
              fontFamily: 'Montserrat, sans-serif',
              '::placeholder': {
                "fontSize": '0.75rem',
                "lineHeight": '1rem',
                "fontWeight": 600,
                color: '#9ca3af',
              },
            },
          }
        }
      }
    },
    subscription: {
      //price_id: 'price_1O8auEDbiI9E3CCDQTRrl4Hn', //BHPA $7.99 USD / month
      //amount: 799
      //price_id: 'price_1OQ4RoDbiI9E3CCDa0XuoTDJ', //BHWPA $1.99 USD / week
      //amount: 0
    },
    _cache: {
      subscribe: {
        user: undefined,
        user_department: undefined,
      },
      error_fields: [],
      error_messages: {
        number: '',
        expire: '',
        cvc: '',
      },
      card: {
        number: false,
        expire: false,
        cvc: false,
      }
    }
  }

  constructor(private store: Store, private cdr: ChangeDetectorRef,  private userService: UserService, private stripeService: StripeService,private router: Router) {
  }

  ngOnInit(): void {
    this.initUser();
    this.initUserStripeCustomer();
  }

  ngOnDestroy(): void {
    if (!this.interface._cache) {return}
    Object.keys(this.interface._cache.subscribe).forEach((_subscribe) => {
      if (this.interface._cache.subscribe[_subscribe]) {
        this.interface._cache.subscribe[_subscribe].unsubscribe();
      }
    });
  }

  initUser(): void {
    this.interface._cache.subscribe.user = this.store.select(UserState.selectUser).subscribe((_user: any) => {
      this.interface.user = {..._user};
      this.interface.payment.stripe.elementsOptions.locale = this.interface.user.settings.language as StripeElementLocale;
      this.cdr.detectChanges();
    });
  }
  initUserStripeCustomer(): void {
    this.userService.getStripeCustomer().subscribe(_customer => {
      this.interface.payment.stripe.customer_id = _customer.customer_id;
    });
  }

  actionCardNumberChange(event): void {
    this.interface._cache.card.number = event.complete;
    if(event.error) {
      this.interface._cache.error_fields.push('number');
      this.interface._cache.error_messages.number = event.error.message;
    } else {
      this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'number');
      this.interface._cache.error_messages.number = '';
    }
  }
  actionCardExpireChange(event): void {
    this.interface._cache.card.expire = event.complete;
    if(event.error) {
      this.interface._cache.error_fields.push('expire');
      this.interface._cache.error_messages.expire = event.error.message;
    } else {
      this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'expire');
      this.interface._cache.error_messages.expire = '';
    }
  }
  actionCardCvcChange(event): void {
    this.interface._cache.card.cvc = event.complete;
    if(event.error) {
      this.interface._cache.error_fields.push('cvc');
      this.interface._cache.error_messages.cvc = event.error.message;
    } else {
      this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'cvc');
      this.interface._cache.error_messages.cvc = '';
    }
  }

  checkPaymentFields(): boolean {
    return !Object.values(this.interface._cache.card).every(field => field === true);
  }

  actionConfirmPayment():void {
    this.interface.submit = true;
    this.stripeService.createPaymentMethod({
      type: 'card',
      card: this.card.element,
      billing_details: {
        name: this.interface.user.display_name,
        email: this.interface.user.email
      }
    }).subscribe((result) => {
      if (result.paymentMethod) {
        let _plan_title = this.interface.plan.title? this.interface.plan.title: 'Custom Premium';
        if(this.interface.plan.with_extra) {
          _plan_title += ' & Science'
        }
        const stripe_user_data = {
          payment_method_id: result.paymentMethod.id,
          customer_id: this.interface.payment.stripe.customer_id,
          price_id: this.interface.plan.with_extra? this.interface.plan.stripe.with_extra: this.interface.plan.stripe.basic,
          trial_period_days: this.interface.plan.trial_period_days,
          title: _plan_title,
          premium_material_id: this.interface.plan.premium_material_id? this.interface.plan.premium_material_id: null,
        }
        this.userService.startStripeSubscription(stripe_user_data).subscribe(_subs => {
          this.interface.submit = false;
          this.store.dispatch(new AuthStatus());
          this.store.dispatch(new ModalCloseAction());
          this.router.navigate(['/account/subscriptions']).then();
        });
      }
      if(result.error) {
        this.interface.submit = false;
      }
    });
  }


}


